<template>
  <v-app>

    <!-- 产品插件 -->
    <v-card :flat="true" class="warps warp2">
      <div class="titleAndImg">
        <div>
          <img :src="form.imgUrl" alt="">
          <div class="titleStyle">
            <span class="title">{{form.title}}</span>
            <span class="tip">{{form.tip}}</span>
          </div>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin: 0 auto" >
          <v-col cols="12" >
            <v-card flat>
              <div class="img_title" justify="center">
                <div class="imgBox rounded-lg" >
                  <vue-qr :correctLevel="1" :margin="10"
                             :size="150"
                             :text="form.downloadUrl"
                             background-color="#f5f7fa"
                           ></vue-qr>
                </div>
                <v-btn class="buttonStyle rounded-pill" height="40" width="278" @click="ApClick">
                  <span  class="font-size-14">安卓应用下载</span>
                </v-btn>
                <div v-if="overlay" class="overlayStyle" @click="overlay = !overlay">
                  <div class="overlayContent">
                    <v-img :src="imgUrl" class="imgStyle"></v-img>
                    <span>请点击右上角菜单，选择在浏览器打开</span>
                  </div>
                </div>
                <p>ios平台即将上线，敬请期待</p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    

  </v-app>
</template>
<script>
import VueQr from "vue-qr";
export default {
  name: 'Home',
  components: { VueQr },
  data() {
    return {
      data: {
        cyxcs: {
          imgUrl: 'https://h5.ophyer.cn/official_website/other/cycs.png',
          title: '成语小测试',
          tip: '提升您的成语水平',
          downloadUrl: 'https://material.ophyer.cn/apk/成语小测验-release.apk',
          appid: 'cyxcs'
        },
        jwxf: {
          imgUrl: 'https://h5.ophyer.cn/official_website/other/jwxf.png',
          title: '箭无虚发',
          tip: '超神射手，箭无虚发',
          downloadUrl: 'https://material.ophyer.cn/apk/箭无虚发-release.apk',
          appid: 'jwxf'
        },
        xcxs: {
          imgUrl: 'https://h5.ophyer.cn/official_website/other/xcxs.png',
          title: '星辰小说',
          tip: '享受每一分钟阅读',
          downloadUrl: 'https://material.ophyer.cn/apk/星辰小说20220318.apk',
          appid: 'xcxs'
        },
        yyxs: {
          imgUrl: 'https://h5.ophyer.cn/official_website/other/yyxs.png',
          title: '云游小说',
          tip: '海量小说，任你畅游',
          downloadUrl: 'https://material.ophyer.cn/apk/云游小说20220314.apk',
          appid: 'yyxs'
        },
        zfxs: {
          imgUrl: 'https://h5.ophyer.cn/official_website/other/zfxs.png',
          title: '掌风小说',
          tip: '智能阅读新体验',
          downloadUrl: 'https://material.ophyer.cn/apk/掌风小说20220318.apk',
          appid: 'zfxs'
        }
      },
      form: {},
      overlay: false,
      imgUrl: require("@/assets/images/指引.png"),
      param: {},
      advertisementList:{
        56978 : '六点追书',
        56979 : '北京摩托汇',
        56980 : '月圣百货',
        56981 : '月圣杂货铺',
        56982 : '文思浅游'	,	
        56983 : '豫晓宝'	,	
        56984 : '有书学院',
        56985 : '午夜轻声说',
        56986 : '玩味长沙'	,	
        56987 : '一秒手表'	,	
        56988 : '家常菜攻略'	,	
        56989 : '壹条短视频',
        56990 : '枕边趣读',
        56991 : '小猪爱看',
        56992 : '小鱼畅玩',	
        56993 : '七狗阅读',	
        56994 : '吉祥天气',
        56995 : '彩虹天气',
        56996 : '星云天气',
        56997 : '小小天气',
        56998 : '妖妖',
        56976:'康康在线',
        56977:'驾校一点通',
      }
    }
  },
  created() {
    
  },
  mounted(){
    const parames = window.location.href.split('?')[1]
    let param = parames.toString().split('&')
    param.map((item) => {
      this.param[item.split('=')[0]] = item.split('=')[1]
    })
    this.gtag('js', new Date());
    this.gtag('config', 'G-97MSH42GQB');
    this.getForm(this.param.appid)
  },
  methods:  {
    getForm(keyType){
      this.form = this.data[keyType]
    },
    ApClick(){
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      let serveDown;
      if(isiOS){
        window.open(this.form.downloadUrl, '_blank')
        serveDown = 'ios_to_Download'
      }else{
        serveDown = 'android_to_Download'
        let ua = u.toLowerCase();
        if(ua.match(/MicroMessenger/i) == "micromessenger"){
          this.overlay = !this.overlay
        }else{
          window.open(this.form.downloadUrl, '_blank')
        }
      }
      console.log('this.param',this.advertisementList[this.param.mediaId])
      this.gtag("event", serveDown,{
        'channel_name':  this.advertisementList[this.param.mediaId],
          'channel_id':  this.param.mediaId,
          'app_name':  this.form.title,
          'app_id':  this.form.appid
      })
    },
    gtag(){
      let dataLayer = window.dataLayer || [];
      dataLayer.push(arguments)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-card__subtitle, .text-subtitle-1{
  line-height:  20px !important;
  padding: 1px 6px 6px 0;
}


.warps {
  margin:  50px auto 0;
  text-align:  center;
  width:  1200px !important;
}


.warp2 {
  .titleAndImg{
    img{
      width: 90px;
      height:  90px;
      margin-bottom: 15px;
    }
    .titleStyle{
      display:  flex;
      align-items:  center;
      justify-content:  center;
      flex-direction:  column;
      .title{
        font-size:  18px;
        font-weight:  600;
        color:  #303234;
      }
      .tip{
        font-size:  14px;
        margin: 18px 0 56px 0;
        color:  #606469;
      }
    }
  }
  .img_title {
    width:  266px;
    margin:  0 auto;
    text-align:  center;
    .imgBox{
      width: 186px;
      height: 186px;
      margin:  0 auto;
      border:  1px solid #E8E9EC;
      img {
        margin:  11px auto;
        width:  164px ;
        height:  164px;
      }
    }
    .buttonStyle{
      margin-top: 26px;
      background:  linear-gradient(90deg, #5CBDFF 0%, #2F88FF 100%);
      box-shadow:  0px 2px 5px 0px rgba(42, 118, 221, 0.24);
      span {
        color:  #FFFFFF;
      }
    }
    p {
      font-size:  14px;
      color:  #606469;
      margin-top:  20px;
    }
    .overlayStyle{
      position:  fixed;
      width:  100%;
      height:  100%;
      top:  0;
      left:  0;
      color:  #FFF;
      background:  rgba(17, 17, 17, 0.7);
      .overlayContent{
        .imgStyle{
          width:  162px;
          height:  160px;
          margin: 6px 0 20px 52%;
        }
      }
    }
  }
}

</style>
